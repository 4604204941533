'use client';

import { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'motion/react';

type AnimatedTextProps = {
  prefix?: string;
  phrases: readonly string[];
  interval?: number;
};

export function AnimatedText({
  prefix = 'Med kompetens och mod skapar vi',
  phrases,
  interval = 3000,
}: AnimatedTextProps) {
  const [currentPhrase, setCurrentPhrase] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentPhrase((prev) => (prev + 1) % phrases.length);
    }, interval);
    return () => clearInterval(timer);
  }, [interval, phrases.length]);

  return (
    <div className="flex flex-col gap-2 sm:gap-4">
      <h1 className="text-balance font-display text-2xl font-bold tracking-tight text-white sm:text-3xl md:text-5xl lg:text-6xl">
        {prefix}
      </h1>
      <div className="mb-8 sm:mb-6">
        <AnimatePresence mode="wait">
          <motion.div
            key={currentPhrase}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.5 }}
            className="text-balance font-display text-2xl font-bold tracking-tight text-white sm:text-3xl md:text-5xl lg:text-6xl"
          >
            {phrases[currentPhrase]}
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  );
}

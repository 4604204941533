import(/* webpackMode: "eager" */ "/home/runner/work/sapling/sapling/node_modules/.pnpm/next@15.2.4_react-dom@19.1.0_react@19.1.0__react@19.1.0/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/sapling/sapling/node_modules/.pnpm/next@15.2.4_react-dom@19.1.0_react@19.1.0__react@19.1.0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["AnimatedText"] */ "/home/runner/work/sapling/sapling/packages/www/src/app/AnimatedText.client.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/sapling/sapling/packages/www/src/images/a-girl-opening-window-blinds.png");
;
import(/* webpackMode: "eager" */ "/home/runner/work/sapling/sapling/packages/www/src/images/a-man-and-a-boy.jpg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/sapling/sapling/packages/www/src/images/a-miniature-house-and-key.jpg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/sapling/sapling/packages/www/src/images/a-woman-having-coffee.jpg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/sapling/sapling/packages/www/src/images/krenk-video-poster.jpg");
